import { useLocation, useParams } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

export function PostHogPageView(): null {
  const location = useLocation();
  const pathname = location.pathname;
  const searchParams = useParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }

      posthog.capture('$pageview', { $current_url: url });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

// Wrap this in Suspense to avoid the `useSearchParams` usage above
// from deopting the whole app into client-side rendering
// See https://nextjs.org/docs/messages/deopted-into-client-rendering
export function SuspendedPostHogPageView() {
  return (
    <Suspense fallback={null}>
      <PostHogPageView />
    </Suspense>
  );
}
