import { useState, useRef, useEffect } from 'react';
import { WizardInputUploadQuestion } from '../WizardConfig';
import { Stack, Typography } from '@mui/material';
import { WizardPagination } from '../WizardPagination';
import { useForm } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Wizard } from '../Wizard';
import { useHistory } from 'react-router';
import {
  TextFieldWithUpload,
  TextFieldWithUploadProps,
} from 'components/ProspectList/AutoCompleteWithUpload';
import { optionsSchema, type Option } from 'hooks/Prospects/useIcpFilter';
import {
  capitalizeFirstChar,
  domain_regex,
  email_regex,
  extractDomainFromUrl,
  pluralize,
} from 'utils/util';
import { endpoints } from 'variables/endpoint-urls';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import { V2 } from '../../../redux/reducers/api/prospects';
import {
  jobTitles,
  organizationSizeRanges,
} from 'components/ProspectList/ICPBuilder/presets';
import {
  fieldConfigurations,
  vendeluxEmployeeFieldConfigurations,
} from 'components/ProspectList/ICPBuilder/CsvUploadModal';
import { autocompletePlaceholder } from '../wizardUtils';
import { optionsApi } from 'redux/reducers/api/options';
import { z } from 'zod';
import { useUserState } from 'hooks/common/useUserState';
import { crmApi } from 'redux/reducers/api/crm';
import isUUID from 'validator/es/lib/isUUID';
import { safeNormalizeUUID } from '../../../utils/uuid';
import { usePosthog } from 'analytics';

export interface AutocompleteWithUploadQuestionProps<TWizard = Wizard> {
  question: WizardInputUploadQuestion;
  onSubmit?: (response: any) => void;
  initialValue?: any;
  onItemSelected?: (values: any[]) => void;
  wizard: TWizard;
}

export function AutocompleteWithUploadQuestion({
  question,
  onSubmit,
  initialValue,
  onItemSelected,
  wizard,
}: AutocompleteWithUploadQuestionProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultValues: Record<string, any> = {};
  const { team } = useUserState();

  const { sendPosthogEvent } = usePosthog();

  const { data: getCrmFilterOptions, isFetching: fetchingCrmFilters } =
    crmApi.useGetCrmFilterOptionsQuery(team.uuid);

  const { data: prospectLists, isFetching: fetchingProspectLists } =
    optionsApi.useGetProspectListOptionsQuery();

  defaultValues[question.name] = initialValue;

  const schema = z.object({
    [question.name]: optionsSchema.min(1, {
      message: 'Please add at least one item.',
    }),
  });

  type Schema = z.infer<typeof schema>;

  // Initialize form with default values from localStorage.
  const form = useForm<Schema>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const [value, setValue] = useState<Option[]>(initialValue || []);
  const label = capitalizeFirstChar(question.subType);

  // Keep react-hook-form state in sync with changes coming from the autocomplete.
  useEffect(() => {
    if (value) {
      form.setValue(question.name, value, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [form, question?.name, value]);

  function submitForm() {
    try {
      if (onSubmit) onSubmit(value);
    } catch (e) {
      // Localstorage can't handle this much data; show a nice message.
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        sendPosthogEvent('List Wizard > Upload Error > Too Large', {
          questionId: question.id,
          wizardId: wizard.config.id,
          wizardVersion: wizard.config.version,
        });
        dispatch(
          setSnacksQueue({
            message:
              'Your upload is too large for the wizard to handle. Try again with fewer rows.',
          })
        );
      }
    }
  }

  function onNextClick() {
    formRef.current?.requestSubmit();
  }

  function onPreviousClick() {
    history.goBack();
  }

  let endpointFunction: TextFieldWithUploadProps<string>['endpointFunction'];
  let filterFetchedOptions: TextFieldWithUploadProps<string>['filterFetchedOptions'];
  let filterUploadedOptions: TextFieldWithUploadProps<string>['filterUploadedOptions'];
  let formatUploadedOptions: TextFieldWithUploadProps<string>['formatUploadedOptions'];
  let fetch = true;
  let options: Option[] = [];
  let allowUpload = true;
  let uploadErrorCopyAppendix = '';

  // If we've set allowUpload in the question, that value overrides the default.
  if (typeof question.allowUpload !== 'undefined') {
    allowUpload = question.allowUpload;
  }

  // TODO: abstract this out when we add another wizard, if needed. These may or may not be list-wizard specific.
  switch (question.subType) {
    case 'organizations':
      endpointFunction = endpoints.prospects.get.v2_autocomplete_organizations;
      filterFetchedOptions = (
        data: V2.GetAutocompleteOrganizationsResponsePayload
      ) => {
        return data.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        }));
      };
      formatUploadedOptions = {
        domain: extractDomainFromUrl,
        uuid: safeNormalizeUUID,
      };
      filterUploadedOptions = {
        domain: (data: string) => domain_regex.test(data),
        uuid: isUUID,
      };
      uploadErrorCopyAppendix =
        ' Common problems include spaces in domain fields and typos.';

      break;

    case 'people':
      uploadErrorCopyAppendix =
        ' Common problems include spaces in email fields, company names instead of emails, and typos.';
      endpointFunction = endpoints.prospects.get.v2_autocomplete_profiles;
      filterFetchedOptions = (
        data: V2.GetAutocompleteProfilesResponsePayload
      ) => {
        return data.map(({ name, uuid, organization__name }) => ({
          label: `${name} (${organization__name})`,
          value: uuid,
        }));
      };
      formatUploadedOptions = {
        uuid: safeNormalizeUUID,
      };
      filterUploadedOptions = {
        email: (data: string) => email_regex.test(data),
        uuid: isUUID,
      };
      break;

    case 'job-titles-keywords':
      fetch = false;
      options = jobTitles.map((title) => ({
        label: title,
        value: title,
        disabled: false,
      }));
      break;

    case 'prospect-lists':
      fetch = fetchingProspectLists;
      options = (prospectLists || []).map((list) => ({
        label: list.label,
        value: list.uuid,
        disabled: false,
      }));
      break;

    case 'organization-size':
      fetch = false;
      options = organizationSizeRanges.map((size) => ({
        label: size,
        value: size,
      }));
      allowUpload = false;
      break;

    case 'crm-owner':
      fetch = fetchingCrmFilters;
      options = getCrmFilterOptions?.success
        ? getCrmFilterOptions.results.grouped_crm_filters.OWNER.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [];
      allowUpload = false;
      break;

    case 'crm-deal-owner':
      fetch = fetchingCrmFilters;
      options = getCrmFilterOptions?.success
        ? getCrmFilterOptions.results.grouped_crm_filters.OWNER.map((item) => ({
            label: item.label,
            value: item.label, // DEV-3683
          }))
        : [];
      allowUpload = false;
      break;

    case 'crm-deal-stage':
      fetch = fetchingCrmFilters;
      options = getCrmFilterOptions?.success
        ? getCrmFilterOptions.results.grouped_crm_filters.DEAL_STAGE.map(
            (item) => ({
              label: item.label,
              value: item.label, // DEV-3683
            })
          )
        : [];
      allowUpload = false;
      break;

    default:
      throw new Error(`Unknown question subType: ${question.subType}`);
  }

  // the label and description can be a function that takes the wizard as an argument
  const questionLabel =
    typeof question.label === 'function'
      ? question.label(wizard)
      : question.label;
  const description =
    typeof question.description === 'function'
      ? question.description(wizard)
      : question.description;

  const { isVendeluxEmployee } = useUserState();

  return (
    <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="h6">
            {questionLabel}
          </Typography>
          {question.description ? (
            <Typography variant="body2">{description}</Typography>
          ) : null}

          <TextFieldWithUpload
            label={label}
            placeholder={
              question.placeholder ??
              (question.subType
                ? autocompletePlaceholder(question.subType)
                : 'Enter data to search for here')
            }
            options={options}
            allowUpload={allowUpload}
            fetch={fetch}
            isLink={false}
            value={value}
            onValueChanged={(newValue) => {
              setValue(() => newValue);
              form.clearErrors(question.name);
            }}
            onRemoveItemClicked={(option) => {
              setValue(value.filter((v) => v.value !== option.value));
              form.clearErrors(question.name);
            }}
            afterHandleCSVSubmit={(filteredOptionsCount) => {
              if (filteredOptionsCount > 0) {
                form.setError(question.name, {
                  type: 'manual',
                  message: `There was an issue with ${filteredOptionsCount} ${pluralize(
                    'row',
                    filteredOptionsCount,
                    '',
                    's'
                  )}. Please check the file and try again. ${uploadErrorCopyAppendix}`,
                });
              }
            }}
            endpointFunction={endpointFunction}
            filterFetchedOptions={filterFetchedOptions}
            filterUploadedOptions={filterUploadedOptions}
            formatUploadedOptions={formatUploadedOptions}
            uploadModalFields={
              isVendeluxEmployee
                ? // @ts-expect-error no idea what this does for unknown sub-type
                  vendeluxEmployeeFieldConfigurations[question.subType]
                : // @ts-expect-error
                  fieldConfigurations[question.subType]
            }
            uploadModalSeparator={
              ['organizations', 'people'].includes(question.subType)
                ? 'Optional:'
                : 'AND'
            }
            textInputProps={{
              // Adds unique data attribute to text input for behavioral tracking (e.g., Pendo).
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
              name: question.name,
            }}
            fileInputProps={{
              // Adds unique data attribute to button
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
            uploadButtonProps={{
              // Adds unique data attribute to button
              // @ts-expect-error
              'data-autocomplete-type': question.subType,
            }}
          />

          {form.formState.errors[question.name] ? (
            <Typography
              color="error"
              variant="caption"
              sx={{
                mt: '3px !important', // to make it look like the error messages from react-hook-form-mui
                ml: '14px !important', // to make it look like the error messages from react-hook-form-mui
              }}
            >
              {form.formState.errors[question.name]?.message}
            </Typography>
          ) : null}
        </Stack>
        <WizardPagination
          wizard={wizard}
          question={question}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Stack>
    </form>
  );
}
