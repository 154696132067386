import { PostHogProvider as PostHogProviderOriginal } from 'posthog-js/react';
import { SuspendedPostHogPageView } from './PostHogPageView';
import { DEBUG } from 'variables/constants';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  debug: process.env.NODE_ENV === 'development' && DEBUG,
};

export interface PostHogProviderProps {
  children: React.ReactNode;
}

export function PostHogProvider({ children }: PostHogProviderProps) {
  return (
    <PostHogProviderOriginal
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <SuspendedPostHogPageView />
      {children}
    </PostHogProviderOriginal>
  );
}
