import {
  WizardConfig,
  WizardTreeSelectAutocompleteQuestion,
} from '../WizardConfig';
import { Stack, Typography } from '@mui/material';
import { WizardPagination } from '../WizardPagination';
import { useForm } from 'react-hook-form-mui';
import { Wizard } from '../Wizard';
import { useHistory } from 'react-router';
import { type Option } from 'hooks/Prospects/useIcpFilter';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';
import {
  Category,
  Option as TreeSelectOption,
  TreeSelectAutocomplete,
} from 'components/common/Inputs/TreeSelectAutocomplete';
import { industryApi } from 'redux/reducers/api/industry';
import { usePosthog } from 'analytics';

export interface TreeSelectAutocompleteQuestionProps<
  TWizard = Wizard<WizardConfig>
> {
  wizard: TWizard;
  question: WizardTreeSelectAutocompleteQuestion;
  onSubmit?: (response: any) => void;
  initialValue?: TreeSelectOption<string>[];
  onItemSelected?: (values: any[]) => void;
}
export function TreeSelectAutocompleteQuestion({
  question,
  onSubmit,
  initialValue = [],
  onItemSelected,
  wizard,
}: TreeSelectAutocompleteQuestionProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultValues: Record<string, any> = {};
  const formRef = useRef<HTMLFormElement>(null);

  const { sendPosthogEvent } = usePosthog();

  defaultValues[question.name] = initialValue;

  const form = useForm({
    defaultValues,
  });

  const [value, setValue] = useState<Option[]>(initialValue || []);

  function handleChange(newValue: Option[]) {
    setValue(newValue);
    if (onItemSelected) {
      onItemSelected(newValue);
    }
  }

  function submitForm() {
    try {
      if (onSubmit) onSubmit(value);
    } catch (e) {
      // Localstorage can't handle this much data; show a nice message.
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        sendPosthogEvent('List Wizard > Upload Error > Too Large', {
          questionId: question.id,
          wizardId: wizard.config.id,
          wizardVersion: wizard.config.version,
        });
        dispatch(
          setSnacksQueue({
            message:
              'Your upload is too large for the wizard to handle. Try again with fewer rows.',
          })
        );
      }
    }
  }

  function onNextClick() {
    formRef.current?.requestSubmit();
  }

  function onPreviousClick() {
    history.goBack();
  }
  const { data: subindustryData } = industryApi.useGetSubindustriesQuery();
  const { data: industryData } = industryApi.useGetIndustriesQuery();
  const { data: industryGroupData } = industryApi.useGetIndustryGroupsQuery();
  const { data: sectorData } = industryApi.useGetSectorsQuery();

  let categoriesData: Category<string>[] = [];
  switch (question.subType) {
    case 'subindustries':
      categoriesData = subindustryData ?? [];
      break;
    case 'industries':
      categoriesData = industryData ?? [];
      break;
    case 'industry-groups':
      categoriesData = industryGroupData ?? [];
      break;
    case 'sectors':
      categoriesData = sectorData ?? [];
      break;
    default:
      throw new Error(`Unknown question subType: ${question.subType}`);
  }

  // the label and description can be a function that takes the wizard as an argument
  const questionLabel =
    typeof question.label === 'function'
      ? question.label(wizard)
      : question.label;
  const description =
    typeof question.description === 'function'
      ? question.description(wizard)
      : question.description;

  return (
    <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="h6">
            {questionLabel}
          </Typography>
          {question.description ? (
            <Typography variant="body2">{description}</Typography>
          ) : null}
          <TreeSelectAutocomplete
            categories={categoriesData}
            customHandleChange={handleChange}
            initialValue={initialValue}
            slotProps={{
              autocomplete: {
                placeholder: question.placeholder,
                isOptionEqualToValue: (option, value) =>
                  option.value === value.value && option.label === value.label,
              },
            }}
          />
        </Stack>
        <WizardPagination
          wizard={wizard}
          question={question}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      </Stack>
    </form>
  );
}
